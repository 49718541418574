import React from 'react'
import ServicePageLayout from '../../components/layouts/service-page-layout'
import { Colors } from '../../colors'

import desktopBackgroundImage from '../../images/services/banner-financement-hypothecaire-desktop.png'
import { AvailableServices } from '../../available-services'
import { ServiceDetail } from '../../components/service-detail/service-detail'
import { FinancementHypothequeForm } from '../../forms/financement-hypotheque-form'
import { PageRow } from '../../components/page-row/page-row'
import Button from '../../components/button/button'
import * as styles from './financement-hypothecaire.module.scss'

export const FinancementHypothecaire = () => {
    return (
        <ServicePageLayout
            title={'Prêt hypothécaire'}
            primaryColor={Colors.skyBlue}
            bannerText={'Prêt hypothécaire. Faites de votre rêve une réalité.'}
            desktopBanner={desktopBackgroundImage}
            mobileBanner={desktopBackgroundImage}
            serviceBrief={
                'Que ce soit pour votre première maison, pour un renouvellement ou des rénovations, nos conseillers contribueront à simplifier ce projet et vous dirigerons vers le financement qui répondra à vos besoins.'
            }
            serviceToHide={AvailableServices.financementHypothecaire}
        >
            <ServiceDetail
                color={Colors.orange}
                title={"Achat d'une nouvelle propriété"}
                details={"Pour le financement d'un nouvelle propriété"}
                icon={'HouseIcon'}
            />
            <ServiceDetail
                color={Colors.skyBlue}
                title={"Accès au Régime d'accession à la propriété"}
                details={"Pour vous permettre d'augmenter la valeur de votre mise de fonds."}
                icon={'HouseIcon'}
            />
            <ServiceDetail
                color={Colors.turquoise}
                title={'Marge de crédit hypothécaire'}
                details={'Pour financer vos projets, qui demandent de la liquidité'}
                icon={'HouseIcon'}
            />
            <ServiceDetail
                color={Colors.purple}
                title={'Renouvellement hypothécaire'}
                details={'Pour un refinancement qui correspond à votre nouvelle réalité'}
                icon={'HouseIcon'}
            />
            <PageRow className={styles.dual}>
                <div>
                    <h2>Int&eacute;ress&eacute; &agrave; obtenir des informations?</h2>
                    <FinancementHypothequeForm />
                </div>
                <div>
                    <Button text="Consultez les taux en vigueur" color={Colors.yellow} onClick={() => window.location.href = 'https://www.nesto.ca/fr/fhg/guillaume'} />
                </div>
            </PageRow>
        </ServicePageLayout>
    )
}

export default FinancementHypothecaire
